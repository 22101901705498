import React, { Component } from 'react';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import { icons } from '../../styles/icons';
import { personProfileLink } from '../../support/person';
import { EditDeleteButtons, colors } from '@united-talent-agency/julius-frontend-components';
import { companyProfileLink } from '../../support/company';

import { searchClient } from '../../support/algolia/algolia-search-client';
import { getPeopleIndex } from '../../support/algolia/get-index';

import { VerificationIcon } from '@united-talent-agency/components';

export function getAge(casting) {
  const minAge = !casting || !casting.ageMin || casting.ageMin === '' ? '?' : casting.ageMin;
  const maxAge = !casting || !casting.ageMax || casting.ageMax === '' ? '?' : casting.ageMax;
  const age = minAge === maxAge ? minAge : minAge + '-' + maxAge;

  return age === '?' ? '' : age; // could be '?' because the min and max might both be falsy or empty
}

const Role = ({ styles, onEditItem, casting }) => {
  const name = casting && casting.name ? casting.name : '';
  return (
    <td
      className={styles.columnFixed}
      onClick={() => {
        onEditItem(casting);
      }}
    >
      <p className={`${styles.data} ${styles.zeroMarginPadding}`} style={{ fontWeight: 'bold' }}>
        {name}
      </p>
    </td>
  );
};

const RoleDescription = ({ styles, onEditItem, casting }) => {
  const age = getAge(casting);
  const displayedAge = getAge(casting) ? ` (${age})` : '';
  const ethnicity = casting && casting.ethnicity ? casting.ethnicity : '';
  const gender = casting && casting.gender ? casting.gender : '';
  const type = casting && casting.type ? casting.type : '';
  return (
    <td
      className={styles.columnFixed}
      onClick={() => {
        onEditItem(casting);
      }}
    >
      <p className={`${styles.data} ${styles.zeroMarginPadding}`}>
        {gender} {displayedAge}
      </p>
      <p className={`${styles.data} ${styles.zeroMarginPadding}`}>{ethnicity}</p>
      <p className={`${styles.data} ${styles.zeroMarginPadding}`}>{type}</p>
    </td>
  );
};

const Priority = ({ styles, onEditItem, casting }) => {
  const priority = casting && casting.priority ? casting.priority : '';
  return (
    <td
      className={styles.columnPriority}
      onClick={() => {
        onEditItem(casting);
      }}
    >
      <p style={{ fontWeight: 'bold', marginBottom: 0 }}>{priority}</p>
    </td>
  );
};

const JobStatus = ({ styles, onEditItem, casting = {}, searchAlgolia = false }) => {
  const { representedByAgency = {}, status = '' } = casting;
  const person = casting && casting.personId ? casting.personId : '';
  const representedBy = casting && casting.representedByAgency ? casting.representedByAgency.name : '';
  const representedByCompanyName = (representedByAgency && representedByAgency.name) || '';
  const company = (representedByAgency && representedByAgency._id) || '';
  const personName = casting && casting.personId ? casting.personId.name : '';
  const personId = casting?.personId?._id;
  const [verified, setVerified] = React.useState(false);
  const [verifiedObj, setVerifiedObj] = React.useState({ verifiedBy: '', verifiedOn: new Date() });
  const isSteward = false; // maintaining old behavior
  const statusStyle = casting && casting.emphasizeCastingPerson && personName !== '' ? { fontWeight: 'bold' } : null;
  const personNameStyle =
    casting && casting.emphasizeCastingPerson ? { fontWeight: 'bold', fontStyle: 'italic' } : null;

  React.useEffect(() => {
    if (searchAlgolia){
      const index = getPeopleIndex();
      const indexSettings = {
        filters: `(objectID: ${personId})`,
        hitsPerPage: 1,
        page: 0
      };
      searchClient(index, '', indexSettings).then(({ hits: results }) => {
        const verifiedBy = results[0]?.verifiedBy ? results[0]?.verifiedBy?.first_name + ' ' + results[0]?.verifiedBy?.last_name : null;
        const verifiedOn = results[0]?.verifiedOn;
        setVerified(verifiedBy && verifiedOn);
        setVerifiedObj({ verifiedBy, verifiedOn });
      });
    }
  }, [personId, searchAlgolia]);

  return (
    <td className={styles.columnFixed} onClick={() => onEditItem(casting)}>
      <p className={`${styles.data} ${styles.zeroMarginPadding}`} style={statusStyle}>
        {status}
      </p>
      <p className={`${styles.person} ${styles.zeroMarginPadding}`} style={personNameStyle}>
        {person ? (
          <a
            className={styles.profileLink}
            href={personProfileLink(person, isSteward)}
            target="_blank"
            rel="noopener noreferrer"
            // so the edit info is not revealed on this click
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <span className='mr-2'>{personName?.length > 15 ? personName?.substring(0, 12) + '...' : personName}</span>
            {verified ? 
            <VerificationIcon
              color="verifiedPersonIcon"
              verifiedBy={verifiedObj.verifiedBy}
              verifiedOn={verifiedObj.verifiedOn}
              identifier={personId}
              includeExplanation
            />
              : null
            }
          </a>
        ) : (
          personName
        )}
      </p>
      <p className={`${styles.company} ${styles.zeroMarginPadding}`}>
        {representedBy ? (
          <a
            className={styles.profileLink}
            href={companyProfileLink(company, isSteward)}
            target="_blank"
            rel="noopener noreferrer"
            // so the edit info is not revealed on this click
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {representedByCompanyName}
          </a>
        ) : (
          representedByCompanyName
        )}
      </p>
    </td>
  );
};

const Description = ({
  styles,
  onEditItem,
  casting,
  showPencil,
  onDeleteItem,
  onArchiveItem,
  index,
  onRestoreItem,
}) => {
  const description = casting && casting.description ? casting.description : '';
  return (
    <React.Fragment>
      <td
        className={styles.columnFluid}
        onClick={() => {
          onEditItem(casting);
        }}
      >
        <p className={`${styles.description} ${styles.zeroMarginPadding}`}>{description}</p>
      </td>
      <td className={classnames(`${styles.pencilSpace} ${!showPencil ? styles.invisiblePencil : ''}`)}>
        {
          <i
            className={`${styles.pencil}`}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onEditItem(casting);
            }}
          />
        }
        {
          <i
            className={`${styles.delete}`}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onDeleteItem(casting);
            }}
          />
        }
        <br />
        {casting.active && (
          <i
            className="fa fa-archive"
            alt="Archive Casting"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              casting.active = false;
              onArchiveItem && onArchiveItem(casting, index);
            }}
          />
        )}
        {!casting.active && (
          <i
            className="fa fa-undo"
            alt="Restore Casting"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              casting.active = true;
              onRestoreItem && onRestoreItem(casting, index);
            }}
          />
        )}
      </td>
    </React.Fragment>
  );
};

class Casting extends Component {
  // eslint-disable-next-line no-undef
  state = {
    showEdit: false,
    showPencil: false,
  };

  render() {
    const {
      casting,
      index,
      isActive,
      isFocused,
      onDeleteItem,
      onEditItem,
      setFocused,
      styles,
      onArchiveItem,
      onRestoreItem,
      searchAlgolia = true,
    } = this.props;
    const { showEdit, showPencil } = this.state;

    return (
      <tr
        className={classnames(
          styles.tr,
          isActive ? styles.tdActive : undefined,
          isFocused ? styles.tdFocus : undefined
        )}
        onMouseEnter={() => {
          setFocused(index);
          this.setState({ showPencil: true });
          setTimeout(() => {
            this.setState({ showEdit: true });
          }, 700);
        }}
        onMouseLeave={() => {
          this.setState({ showEdit: false, showPencil: false });
        }}
      >
        <Role onEditItem={onEditItem} styles={styles} casting={casting} />
        <RoleDescription styles={styles} onEditItem={onEditItem} casting={casting} />
        <Priority casting={casting} styles={styles} onEditItem={onEditItem} />
        <JobStatus casting={casting} styles={styles} onEditItem={onEditItem} searchAlgolia={searchAlgolia} />
        <Description
          styles={styles}
          onRestoreItem={onRestoreItem}
          onArchiveItem={onArchiveItem}
          showPencil={showPencil}
          index={index}
          onEditItem={onEditItem}
          casting={casting}
          onDeleteItem={onDeleteItem}
        />
        {isFocused && showEdit && (
          <EditDeleteButtons entity={casting} onEditItem={onEditItem} onDeleteItem={onDeleteItem} />
        )}
      </tr>
    );
  }
}

const withStyles = styled({
  tr: {
    '> td': {
      padding: 15,
      fontSize: 12,
      fontWeight: 300,
      color: colors.text,
    },
    '&:nth-child(even)': {
      backgroundColor: '#e8e8e8',
      '&:hover': {
        backgroundColor: '#c2c2c2',
      },
    },
    '&:nth-child(odd)': {
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: '#c2c2c2',
      },
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
  },
  tdFocus: {
    backgroundColor: colors.focusBackground,
  },
  tdActive: styles => ({
    border: `1px solid ${colors.focusBorder}`,
    [`& + .${styles.tr}`]: {
      borderTop: 0,
    },
  }),
  container: {
    display: 'flex',
    xJustifyContent: 'space-around',
    xAlignItems: 'stretch',
  },
  columnFixed: {
    overflowX: 'auto',
    width: '50px',
    flex: '0 0 50px ',
  },
  columnPriority: {
    width: 10,
  },
  columnFluid: {
    border: 'none',
  },
  person: {
    textOverflow: 'ellipsis',
  },
  company: {
    textOverflow: 'ellipsis',
  },
  zeroMarginPadding: {
    margin: 0,
    padding: 0,
  },
  data: {
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 115,
  },
  description: {
    display: 'flex',
    textOverflow: 'ellipsis',
  },
  profileLink: {
    cursor: 'pointer',
    color: '#000',
  },
  pencil: {
    ...icons.pencil,
    zIndex: 900,
    right: '0',
    top: '0',
    textAlign: 'right',
    display: 'flex',
  },
  delete: {
    ...icons.smallCrossBlack,
    zIndex: 900,
    marginTop: '20px',
    textAlign: 'right',
    display: 'flex',
  },
  invisiblePencil: {
    opacity: 0,
  },
  pencilSpace: {
    verticalAlign: 'top',
  },
});

export default withStyles(Casting);
